import React from 'react'
import styled from '@emotion/styled'
import {
  height,
  HeightProps,
  MarginProps,
  space,
  SpaceProps
} from 'styled-system'
import {
  DEFAULT_FALLBACK_IMAGE,
  DEFAULT_FALLBACK_IMAGE_NAME
} from 'enums/image'
import useTranslation from 'next-translate/useTranslation'

export interface CoverImageProps extends HeightProps, SpaceProps, MarginProps {
  /**
   * Takes a string. corresponds to the background.
   */
  src?: string
  /**
   * Takes ReactFragment and string. corresponds to the content displayed on the top of the image.
   */
  top?: React.ReactNode
  /**
   * Takes ReactFragment and string. corresponds to the content displayed on the bottom of the image.
   */
  bottom?: React.ReactNode
  /**
   * Takes a boolean. corresponds to the overlay.
   */
  overlay?: boolean
  /**
   * hero: the image will be hero size and centred.
   * thumb: the image will have rounded corners.
   */
  variant?: 'hero' | 'thumb'
  /**
   * Takes a string to output a class. Related to emotion - It lets another component style it
   */
  className?: string
}

/**
 * The CoverImage component is used for rendering images.
 *
 * It uses the styled-system to handle all the props related to space.
 * Pass the rest of the props "...other" to the element that should be styled with styled-system.
 * */

const CoverImage = ({
  height = 165,
  src = DEFAULT_FALLBACK_IMAGE,
  top,
  bottom,
  overlay,
  variant,
  className,
  ...other
}: CoverImageProps) => {
  const { t } = useTranslation('common')

  // check if doesn't have top and bottom elements
  const imageOnly = top === undefined && bottom === undefined

  return (
    <StyledCoverImage
      src={src}
      top={top}
      variant={variant}
      className={className}
      role={imageOnly ? 'img' : undefined} // for assistive technology
      aria-label={t('cover-image')} // for assistive technology
      height={height}
      {...other}
    >
      {top && <StyledTop>{top}</StyledTop>}
      {bottom && <StyledBottom>{bottom}</StyledBottom>}
    </StyledCoverImage>
  )
}

export default styled(CoverImage)()

const StyledCoverImage = styled.div<CoverImageProps>(
  ({ theme, src }) => ({
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    marginBottom: theme.space[2],
    color: theme.colors.white,
    /**
     * The fallback image uses a combination of two images, the first image is a
     * pattern of icons that repeat themselves if the content is wilder than 330px
     * and the second image is a gradient placed behind the icons. */
    background: src?.includes(DEFAULT_FALLBACK_IMAGE_NAME)
      ? `url(${src}) repeat center / 330px auto, linear-gradient(20deg, #F4873D -120%, #F4873D -120%, #EC165A -12%, ${theme.colors.primary} 95%, #22A9E1 200%)`
      : `url(${src}) no-repeat center / cover`
  }),
  ({ variant, theme }) => {
    switch (variant) {
      case 'thumb':
        return {
          borderRadius: theme.radii[2]
        }
      case 'hero':
        return {
          ...theme.mixins.centeredContainer(theme.currentMaxWidth)
        }
    }
  },
  height,
  space
)

const StyledTop = styled.div(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  zIndex: 0,
  marginTop: theme.space[2],
  marginRight: theme.space[2],
  marginLeft: 'auto'
}))

const StyledBottom = styled.div(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  right: theme.space[2],
  bottom: theme.space[2],
  display: 'flex'
}))
