import React, { ReactNode } from 'react'
import styled from '@emotion/styled'

const ItemBottom = ({ children }: { children?: ReactNode }) => {
  return <StyledItemBottom>{children}</StyledItemBottom>
}

export default ItemBottom

const StyledItemBottom = styled('div')(() => ({
  marginTop: 'auto'
}))
